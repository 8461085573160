import { EmailOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../../components/Header";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [showEmail, setShowEmail] = useState(false);
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [showConfirmNewEmail, setShowConfirmNewEmail] = useState(false);

  const handleFormSubmit = async (values) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.patch(
        `https://webback.piegroupllc.com/admin/changeEmail/2`,
        {
          oldEmail: values.oldEmail,
          newEmail: values.newEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAlertSeverity("success");
        setAlertMessage("Email changed successfully.");
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
          localStorage.removeItem("accessToken");
          navigate("/");
        }, 2000);
      } else if (response.status === 400) {
        setAlertSeverity("error");
        setAlertMessage("Incorrect old email address!");
        setOpenSnackbar(true);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to change email. Please try again.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to change email. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleClickShowEmail = () => {
    setShowEmail(!showEmail);
  };

  const handleClickShowNewEmail = () => {
    setShowNewEmail(!showNewEmail);
  };

  const handleClickShowConfirmNewEmail = () => {
    setShowConfirmNewEmail(!showConfirmNewEmail);
  };

  const handleMouseDownEmail = (event) => {
    event.preventDefault();
  };

  return (
    <Box m="20px">
      <Header title="Change Email Address" subtitle="" />

      <Box justifyContent="center" margin="20px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={emailSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type={"email"}
                    label="Enter Your Current Email Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.oldEmail}
                    name="oldEmail"
                    error={!!touched.oldEmail && !!errors.oldEmail}
                    helperText={touched.oldEmail && errors.oldEmail}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowEmail}
                            onMouseDown={handleMouseDownEmail}
                            edge="end"
                          >
                            <EmailOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type={"email"}
                    label="New Email Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newEmail}
                    name="newEmail"
                    error={!!touched.newEmail && !!errors.newEmail}
                    helperText={touched.newEmail && errors.newEmail}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowNewEmail}
                            onMouseDown={handleMouseDownEmail}
                            edge="end"
                          >
                            <EmailOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type={"email"}
                    label="Confirm New Email Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmNewEmail}
                    name="confirmNewEmail"
                    error={
                      !!touched.confirmNewEmail && !!errors.confirmNewEmail
                    }
                    helperText={
                      touched.confirmNewEmail && errors.confirmNewEmail
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmNewEmail}
                            onMouseDown={handleMouseDownEmail}
                            edge="end"
                          >
                            <EmailOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="left" mt={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#6870fa",
                      color: "white",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#3e4396",
                      },
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const emailSchema = yup.object().shape({
  oldEmail: yup
    .string()
    .email("Invalid email format")
    .required("Current Email Address is required"),
  newEmail: yup
    .string()
    .email("Invalid email format")
    .required("New Email Address is required")
    .notOneOf(
      [yup.ref("oldEmail")],
      "New Email Address must be different from the current one"
    ),
  confirmNewEmail: yup
    .string()
    .email("Invalid email format")
    .oneOf([yup.ref("newEmail"), null], "Emails must match")
    .required("Confirm New Email Address is required"),
});

const initialValues = {
  oldEmail: "",
  newEmail: "",
  confirmNewEmail: "",
};

export default ChangeEmail;
