import ImageIcon from "@mui/icons-material/Image";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const Index = () => {
  const [imageURL, setImageURL] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await axios.get(
          "https://webback.piegroupllc.com/homePage/getAllImages"
        );
        const imageData = response.data.imageData;
        if (imageData.length > 0) {
          setImageURL(imageData[0].imageURL);
        }
      } catch (error) {
        console.log("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setImageURL("");
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("imageURL", selectedImage);

    try {
      setUploading(true);
      await axios.put(
        "https://webback.piegroupllc.com/homePage/updateImage/1",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSnackbarMessage("Image updated successfully!");
      setSnackbarOpen(true);
      setSelectedImage(null);
    } catch (error) {
      setSnackbarMessage("Failed to update the image.");
      setSnackbarOpen(true);
      console.log("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Box m={"20px"}>
        <Header title="Home Page" />

        {imageURL ? (
          <Box mt={2}>
            <Typography sx={{ fontStyle: "bold" }}>Image Preview:</Typography>
            <img
              src={imageURL}
              alt="homePageImage"
              style={{ maxWidth: "40%", height: "auto" }}
            />
          </Box>
        ) : (
          <Typography></Typography>
        )}
      </Box>

      <Box m={2}>
        <Button
          variant="contained"
          component="label"
          color="primary"
          sx={{
            backgroundColor: "#6870fa",
            color: "white",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "#3e4396",
            },
          }}
        >
          Change Image
          <input type="file" hidden onChange={handleImageChange} />
        </Button>
      </Box>

      {previewImage && (
        <Box m={2}>
          <Typography
            display="flex"
            alignItems="center"
            sx={{ fontWeight: "bold" }}
          >
            <ImageIcon sx={{ mr: 1 }} /> Selected Image Preview:
          </Typography>
          <img
            src={previewImage}
            alt="Selected"
            style={{ maxWidth: "40%", height: "auto" }}
          />
        </Box>
      )}

      {selectedImage && !uploading && (
        <Box m={2}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "#6870fa",
              color: "white",
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "#3e4396",
              },
            }}
            onClick={handleImageUpload}
          >
            Save Changes
          </Button>
        </Box>
      )}

      {uploading && (
        <Box mt={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Index;
