import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";

const BlogView = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `https://webback.piegroupllc.com/homePage/getLinkById/1`
        );
        if (response.data.status) {
          setBlogData(response.data.data);
          console.log(response.data);
        } else {
          throw new Error(response.data.message);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSnackbarMessage("Error fetching blog data");
        setSnackbarOpen(true);
      }
    };

    fetchBlogData();
  }, [id]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderGridItem = (label, value) => (
    <>
      <Grid item xs={2}>
        <Typography variant="body1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1">:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );

  const handleEditClick = () => {
    navigate(`/social/editLinks`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error fetching data</Typography>;

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title={`View Social Links`} subtitle="" />

      {blogData && (
        <Grid container spacing={2}>
          {/* {renderGridItem("ID", blogData.id)} */}
          {renderGridItem("Facebook Link", blogData.fbLink)}
          {renderGridItem("Instagram Link", blogData.instagramLink)}
          {renderGridItem("TikTok Link", blogData.tikTokLink)}
          {renderGridItem("YouTube Link", blogData.youtubeLink)}
          {/* {renderGridItem("Created At", formatDate(blogData.createdAt))}
          {renderGridItem("Updated At", formatDate(blogData.updatedAt))} */}

          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditClick}
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Edit Social Media Links
            </Button>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default BlogView;
